import { Title } from "@solidjs/meta";
import { MetaTags } from "~/components/shared/MetaTags";
import Enga4Page from "~/components/Enga/Enga4Page";

export default function () {
  const metaDesc = {
    tag: "meta",
    attributes: {
      name: "description",
      content:
        "Construire des biens qui font du bien, c’est intégrer des espaces extérieurs dans tous nos logements, comme des terrasses aménageables.",
    },
  };

  return (
    <>
      <Title>Engagement Cogedim - Des aménagements intelligents</Title>
      <MetaTags tags={[metaDesc]} />
      <Enga4Page />
    </>
  );
}
